import React from "react";
import { Container } from "reactstrap";
import Helmet from "react-helmet";
import { useRouter } from "next/router";

export default function Feefo() {
  const path = useRouter().asPath;

  return (
    <>
      <Helmet>
        {path === "/job-seekers" ?
          <script
            type="text/javascript"
            src="https://api.feefo.com/api/javascript/lorien?tags=campaign%3Dplacedcandidates"
            async
          />
        :
          <script
            type="text/javascript"
            src="https://api.feefo.com/api/javascript/lorien"
            async
          />
        }
      </Helmet>

      <section className={`position-relative text-center`}>
        <Container>
          <div
            id="feefo-service-review-carousel-widgetId"
            className="feefo-review-carousel-widget-service overflow-hidden"
          />
        </Container>
      </section>
    </>
  );
}
